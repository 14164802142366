import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Col, Row
} from "reactstrap";

function MasterAgreementForm({
  setIsValid, customerId, isValid, setPayload
}) {
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const { products } = useSelector((state) => state.ibAgreements);
  const markups = useSelector((state) => state.dictionaryReducer.markups);
  
  const [agreement, setAgreement] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "TITLE":
          return {
            ...state,
            title: action.value
          };
        case "INIT_MEMBERS":
          return {
            ...state,
            members: action.value
          };
        case "INIT_VALUES":
          return {
            ...state,
            members: state.members.map((member) => {
              return {
                ...member,
                values: action.value
              };
            })
          };
        case "UPDATE_REBATE":
          return {
            ...state,
            members: state.members.map((member) => {
              return {
                ...member,
                values: member.values.map((acc) => {
                  if (acc.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...acc,
                      products: {
                        ...acc.products,
                        [action.payload.productId]: {
                          ...acc.products[action.payload.productId],
                          rebate: parseInt(action.payload.value)
                        }
                      }
                    };
                  }
                  return acc;
                })
              };
            })
          };
        case "UPDATE_COMMISSION":
          return {
            ...state,
            members: state.members.map((member) => {
              return {
                ...member,
                values: member.values.map((acc) => {
                  if (acc.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...acc,
                      products: {
                        ...acc.products,
                        [action.payload.productId]: {
                          ...acc.products[action.payload.productId],
                          commission: parseInt(action.payload.value)
                        }
                      }
                    };
                  }
                  return acc;
                })
              };
            })
          };
        default: 
          return state;
      }
    },
    {
      title: "",
      members: [{
        customerId,
        level: 1,
        values: []
      }]
    }
  );

  useEffect(() => {
    const isAgreementValid = agreement.members[0].values.every((acc) => {
      return Object.values(acc.products).every((prod) => {
        return prod.rebate !== null && prod.commission !== null && prod.rebate >= 0 && prod.commission >= 0;
      });
    }) && agreement.title !== "";
    setIsValid(isAgreementValid);
  }, [agreement.members, agreement.title]);

  useEffect(() => {
    if (isValid) {
      setPayload({
        type: "MASTER",
        payload: agreement
      });
    }
  }, [isValid]);

  useEffect(() => {
    const values = [];
    accountTypes
      .filter((acc) => acc.type?.toLowerCase() !== "demo")
      .forEach((acc) => {
        values.push({
          accountTypeId: acc._id,
          products: products.reduce((obj, prod) => {
            obj[prod] = {
              rebate: null,
              commission: null
            };
            return obj;
          }, {}),
          markup: ""
        });
      });
    setAgreement({
      type: "INIT_VALUES",
      value: values
    });     
  }, [accountTypes, products]);

  const { t } = useTranslation();
  return (
    <Row>
      <AvForm
      >
        <AvField
          name={"title"}
          label={t("Agreement name")}
          className="mb-3"
          type="text"
          errorMessage={t("Required!")}
          validate={{ required: { value: true } }}
          value={agreement.title}
          onChange={(e) => setAgreement({
            type: "TITLE",
            value: e.target.value
          })}
        />
        {accountTypes?.map((acc, accIdx) =>
          <>
            <Row key={accIdx}>
              <Row className="justify-content-end mb-2 fw-bold gx-0">
                <Col md="3" className="text-center">{acc.title}</Col>
                <Col className="ms-1">{t("Rebate")}</Col>
                <Col>{t("Commission")}</Col>
              </Row>
              {products.map((prod, prodIdx) =>
                <Row key={prodIdx} className="my-1 align-items-center">
                  <Col md="3" className="text-center">
                    {prod}
                    <AvField
                      name={`values[${accIdx}].accountTypeId`}
                      value={acc._id}
                      type="hidden"
                    />
                  </Col>
                  <Col>
                    <AvField
                      name={`values[${accIdx}].products.${prod}.rebate`}
                      type="string"
                      errorMessage={t("Invalid Rebate!")}
                      validate={{
                        required: { value: true },
                        min: { value: 0 }
                      }}
                      onChange={(e) => setAgreement({
                        type: "UPDATE_REBATE",
                        payload: {
                          accountTypeId: acc._id,
                          productId: prod,
                          value: e.target.value
                        }
                      })}
                    />
                  </Col>
                  <Col>
                    <AvField
                      name={`values[${accIdx}].products.${prod}.commission`}
                      type="string"
                      errorMessage={t("Invalid commission!")}
                      validate={{
                        required: { value: true },
                        min: { value: 0 }
                      }}
                      onChange={(e) => setAgreement({
                        type: "UPDATE_COMMISSION",
                        payload: {
                          accountTypeId: acc._id,
                          productId: prod,
                          value: e.target.value
                        }
                      })}
                    />
                  </Col>
                </Row>
              )}
              <Row key={accIdx} className="my-1 align-items-center">
                <Col md="3" className="text-center">
                  {t("Markup")}
                </Col>
                <Col>
                  <AvFieldSelect
                    name={`values[${accIdx}].markup`}
                    options={(markups || []).map((obj) => {
                      return ({
                        label: `${obj}`,
                        value: obj
                      });
                    })}
                  />
                </Col>
              </Row>
              <hr className="my-3" />
            </Row>
          </>
        )}
      </AvForm>
    </Row>
  );
}

export default memo(MasterAgreementForm);