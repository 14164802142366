export const FETCH_IB_REQUESTS_START = "FETCH_IB_REQUESTS_START";
export const FETCH_IB_REQUESTS_SUCCESS = "FETCH_IB_REQUESTS_SUCCESS";
export const FETCH_IB_REQUESTS_ERROR = "FETCH_IB_REQUESTS_ERROR";


export const IB_REQUEST_APPROVE_START = "IB_REQUEST_APPROVE_START";
export const IB_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS = "IB_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const IB_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR = "IB_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";


export const IB_REQUEST_REJECT_START = "IB_REQUEST_REJECT_START";
export const IB_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS = "IB_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const IB_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR = "IB_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

//leverage requests
export const FETCH_LEVERAGE_REQUESTS_START = "FETCH_LEVERAGE_REQUESTS_START";
export const FETCH_LEVERAGE_REQUESTS_SUCCESS = "FETCH_LEVERAGE_REQUESTS_SUCCESS";
export const FETCH_LEVERAGE_REQUESTS_ERROR = "FETCH_LEVERAGE_REQUESTS_ERROR";


export const LEVERAGE_REQUEST_APPROVE_START = "LEVERAGE_REQUEST_APPROVE_START";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS = "LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR = "LEVERAGE_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";


export const LEVERAGE_REQUEST_REJECT_START = "LEVERAGE_REQUEST_REJECT_START";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS = "LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR = "LEVERAGE_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

// Requests for new Account
export const FETCH_ACCOUNT_REQUESTS_START = "FETCH_ACCOUNT_REQUESTS_START";
export const FETCH_ACCOUNT_REQUESTS_SUCCESS = "FETCH_ACCOUNT_REQUESTS_SUCCESS";
export const FETCH_ACCOUNT_REQUESTS_ERROR = "FETCH_ACCOUNT_REQUESTS_ERROR";


export const ACCOUNT_REQUEST_APPROVE_START = "ACCOUNT_REQUEST_APPROVE_START";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS = "ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_SUCCESS";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR = "ACCOUNT_REQUEST_STATUS_CHANGE_TO_APPROVE_ERROR";


export const ACCOUNT_REQUEST_REJECT_START = "ACCOUNT_REQUEST_REJECT_START";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS = "ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_SUCCESS";
export const ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR = "ACCOUNT_REQUEST_STATUS_CHANGE_TO_REJECT_ERROR";

export const CLEAN_UP_REQUESTS = "CLEAN_UP_REQUESTS";