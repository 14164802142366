/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// i18n
import { withTranslation } from "react-i18next";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { checkAllBoxes } from "common/utils/checkAllBoxes";
// import Notification from "components/Common/Notification";
// import logo from "assets/images/logo-sm.svg";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";
import formatDate from "helpers/formatDate";
import sleep from "sleep-promise";
import InternalTransferModal from "pages/Transactions/Forex/internalTransfer/InternalTransferModal";
import {
  approveWalletTransfer, fetchWalletTransfer, rejectWalletTransfer
} from "store/wallet/transfer/actions";
import CustomDropDown from "components/Common/CustomDropDown";
import {
  approveInternalTransfer, fetchInternalTransfers, rejectInternalTransfer
} from "store/forexTransactions/internalTransfers/actions";

function ClientInternalTransfers(props) {
  const clientId = props.clientId;
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedTransferType, setSelectedTransferType] = useState("Wallet");
  const transactionTypes = ["Wallet", "Forex"];
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const {internalTransfers} = permissions;
  const dispatch = useDispatch();
  const { actions } = useSelector((state) => state.Profile.depositsPermissions);
  const {
    docs,
    loading,
    pagination,
  } = useSelector((state) => ({
    docs: selectedTransferType === "Wallet" ? state.walletReducer?.transfers?.requests || [] : state.internalTransferReducer?.internalTransfers || [],
    loading: selectedTransferType === "Wallet" ? state.walletReducer.loading : state.internalTransferReducer?.fetchInternalTransfersLoading,
    pagination:
    selectedTransferType === "Wallet"
        ? state.walletReducer?.transfers?.requestsPagination
        : state.internalTransferReducer,
  }));

  const changeTransactionTypeHandler = (e) => {
    setSelectedTransferType(e.target.innerText);
  };
  const loadTransfers = (page, limit) => {
    selectedTransferType?.toUpperCase() === "WALLET" ?
    dispatch(
      fetchWalletTransfer({
        limit,
        page,
        filteredValues: {
          customerId: clientId,
        },
      })
    ) : dispatch(
      fetchInternalTransfers({
        limit,
        page,
        filteredValues: {
          customerId: clientId,
        }
      })
    );
  };

  const walletColumns = [
    {
      dataField: "checkbox",
      text: (
        <input
          type="checkbox"
          id="check-all-deposits"
          onChange={() =>
            checkAllBoxes("check-all-deposits", ".deposit-checkbox")
          }
        />
      ),
    },
    {
      dataField: "recordId",
      text: props.t("Transaction Id"),
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "Client",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>
              {val?.customerId
                ? `${captilazeFirstLetter(
                    val?.customerId?.firstName
                  )} ${captilazeFirstLetter(val?.customerId?.lastName)}`
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "baseCurrency",
      text: props.t("From Currency"),
    },
    {
      dataField: "targetCurrency",
      text: props.t("To Currency"),
    },
    {
      dataField: "fromId",
      text: props.t("From"),
      formatter: (item) =>
        item.source === "WALLET"
          ? `WALLET - ${item?.fromId?.asset}`
          : `Trading Account -${item?.fromId?.login}`,
    },
    {
      dataField: "toId",
      text: props.t("To"),
      formatter: (item) =>
        item.destination === "WALLET"
          ? `WALLET - ${item?.toId?.asset}`
          : `Trading Account -${item?.toId?.login}`,
    },
    {
      dataField: "fee",
      text: props.t("Fee"),
    },
    {
      dataField: "amount",
      text: props.t("Base Amount"),
    },
    {
      dataField: "conversionRate",
      text: props.t("Conversion Rate"),
      formatter: (val) => val?.conversionRate?.toFixed(2),
    },
    {
      dataField: "targetAmount",
      text: props.t("Converted Amount"),
      formatter: (val) => val?.targetAmount?.toFixed(2),
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => (
        <Badge className={val?.status} status={val?.status} />
      ),
    },
    internalTransfers.actions &&
    {
      dataField: "dropdown",
      isDummyField: true,
      editable: false,
      text: props.t("Actions"),
      formatter: (row) => {
        return <CustomDropDown
          status={row.status}
          approve={() => approve(row)}
          reject={() => reject(row)}
          permission={actions}
        />;
      }
    },
  ];

  const forexColumns = [
    {
      dataField: "checkbox",
      text: (
        <input
          type="checkbox"
          id="check-all-deposits"
          onChange={() =>
            checkAllBoxes("check-all-deposits", ".deposit-checkbox")
          }
        />
      ),
    },
    {
      dataField: "recordId",
      text: props.t("Transaction Id"),
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "Client",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <span className="no-italics" style={{ fontWeight: "bold" }}>
              {val?.customerId
                ? `${captilazeFirstLetter(
                    val?.customerId?.firstName
                  )} ${captilazeFirstLetter(val?.customerId?.lastName)}`
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "currency",
      text: props.t("Currency"),
    },
    {
      dataField: "tradingAccountFrom",
      text: props.t("From Account"),
      formatter: (item) =>
        item.tradingAccountFrom && item.tradingAccountFrom.login,
    },
    {
      dataField: "tradingAccountTo",
      text: props.t("To Account"),
      formatter: (item) => item.tradingAccountTo && item.tradingAccountTo.login,
    },
    {
      dataField: "fee",
      text: props.t("Fee"),
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => <Badge status={val.status} />,
    },
    internalTransfers.actions &&
    {
      dataField: "dropdown",
      isDummyField: true,
      editable: false,
      text: props.t("Actions"),
      formatter: (row) => {
        return <CustomDropDown
          status={row.status}
          approve={() => approve(row)}
          reject={() => reject(row)}
          permission={actions}
      />;
      }
    },
  ];

  useEffect(() => {
    loadTransfers(1, sizePerPage);
  }, [selectedTransferType]);

  const approve = (transaction) => {
    if (selectedTransferType?.toUpperCase() === "WALLET") {
      dispatch(approveWalletTransfer(transaction._id));
    } else {
      dispatch(
        approveInternalTransfer({
          id: transaction._id,
        })
      );
    }
    sleep(1000).then(() => {
      loadTransfers(1, sizePerPage);
    });
  };

  const reject = (transaction) => {
    if (selectedTransferType?.toUpperCase() === "WALLET") {
      dispatch(rejectWalletTransfer(transaction._id));
    } else {
      dispatch(
        rejectInternalTransfer({
          id: transaction._id,
        })
      );
    }
    sleep(1000).then(() => {
      loadTransfers(1, sizePerPage);
    });
  };

  const columns = selectedTransferType === "Wallet" ? walletColumns : forexColumns;

  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">
                    {props.t(`Internal Transfers (${(internalTransfers.get || internalTransfers.getAssigned) ? pagination?.totalDocs : ""})`)}
                  </CardTitle>
                  <div className="d-flex gap-2 flex-column flex-md-row">
                    {internalTransfers.create && (
                      <div className="d-inline-block me-2">
                        <InternalTransferModal show={true} customerId={{
                            _id: clientId,
                          }}
                        />
                      </div>
                    )}
                    <Dropdown
                      isOpen={btnprimary1}
                      className="d-inline-block"
                      toggle={() => {
                        setBtnprimary1(!btnprimary1);
                      }}
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        {selectedTransferType}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {transactionTypes.map((transactionType) => (
                          <DropdownItem
                            key={transactionTypes.indexOf(transactionType)}
                            onClick={(e) => {
                              changeTransactionTypeHandler(e);
                            }}
                          >
                            {props.t(transactionType)}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {(internalTransfers.get || internalTransfers.getAssigned) && columns.map((column, index) =>
                              <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                            )}
                          </Tr>
                        </Thead>
                        {
                          // if deposits is selected then render clientDeposits
                          pagination?.totalDocs === 0 ? (
                              <Tbody>
                                {loading && (
                                  <TableLoader colSpan={4} />
                                )}
                                {!loading && (
                                  <>
                                    <Tr>
                                      <Td
                                        colSpan={"100%"}
                                        className="fw-bolder text-center"
                                        st="true"
                                      >
                                        <h3 className="fw-bolder text-center">
                                          No records
                                        </h3>
                                      </Td>
                                    </Tr>
                                  </>
                                )}
                              </Tbody>
                            ) : (
                              <Tbody
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                  textTransform: "capitalize",
                                }}
                              >
                                {loading && (
                                  <TableLoader colSpan={4} />
                                )}
                                {!loading && (internalTransfers.get || internalTransfers.getAssigned) ?
                                  docs.map((row, rowIndex) => (
                                    <Tr key={rowIndex}>
                                      {columns.map((column, index) => (
                                        <Td key={`${rowIndex}-${index}`}>
                                          {column.dataField === "checkbox" ? (
                                            <input type="checkbox" />
                                          ) : (
                                            ""
                                          )}
                                          {column.formatter
                                            ? column.formatter(row, rowIndex)
                                            : row[column.dataField]}
                                        </Td>
                                      ))}
                                    </Tr>
                                  )) : (
                                <Tr>
                                  <Td colSpan={"100%"} className="fw-bolder text-center" st="true">
                                    <h3 className="fw-bolder text-center">{props.t("You Do Not Have The Required Permission")}</h3>
                                  </Td>
                                </Tr>
                              ) }
                              </Tbody>
                            )
                        }
                      </Table>
                    </div>
                  </div>
                </CardBody>
                {(internalTransfers.get || internalTransfers.getAssigned) && (
                  <CustomPagination
                    {...pagination}
                    totalDocs={pagination?.totalDocs}
                    docs={docs}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadTransfers}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(ClientInternalTransfers);
